import store from '@/state/store'
import {AccountStatus, SessionLoginState} from '@/state/helpers'

export default [
  {
    path: '',
    name: 'index',
    //component: () => import('@/router/view/blank'),
    meta: {
      login_required: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (store.state.account === AccountStatus.IN_SETUP) {
          next({name: 'setup_account'})
        } else {
          // Continue
          next({name: 'dummy'});
          //location.replace('https://cftools.de/?completed_new_login=1');
        }
      },
    },
  },
  {
    path: '/redirect',
    name: 'dummy',
    meta: {
      login_required: true,
      beforeResolve(routeTo, routeFrom, next) {
        if(process.env.NODE_ENV === 'production') location.replace('https://app.cftools.cloud');
        else location.replace('https://app.cftools.local:8080');
      }
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/router/view/account/login'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.state.login === SessionLoginState.LOGGED_IN) {
          // Redirect to the home page instead
          next({name: 'index'});
        } else if(store.state.login === SessionLoginState.VERIFY_2FA) {
          next({name: 'verify_2fa'});
        } else if(store.state.login === SessionLoginState.VERIFY_LEGAL) {
          next({name: 'setup_legal'});
        } else if(store.state.login === SessionLoginState.IN_SETUP) {
          next({name: 'setup_account'});
        } else if(store.state.login === SessionLoginState.PASSWORD_PROMPT) {
          next({name: 'authgateway_password'});
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  {
    path: '/login/password',
    name: 'authgateway_password',
    component: () => import('@/router/view/account/password-prompt'),
    meta: {
      auth_required: true
    },
  },
  {
    path: '/login/totp',
    name: 'verify_2fa',
    component: () => import('@/router/view/account/totp-prompt.vue'),
    meta: {
      auth_required: true
    },
  },
  {
    path: '/login/return',
    name: 'authgateway_return',
    meta: {
      auth_required: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (store.state.login === SessionLoginState.LOGGED_IN) {
          next({name: 'index'});
        } else if(store.state.login === SessionLoginState.VERIFY_2FA) {
          next({name: 'verify_2fa'});
        } else if(store.state.login === SessionLoginState.VERIFY_LEGAL) {
          next({name: 'setup_legal'});
        } else if(store.state.login === SessionLoginState.IN_SETUP) {
          next({name: 'setup_account'});
        } else if(store.state.login === SessionLoginState.PASSWORD_PROMPT) {
          next({name: 'authgateway_password'});
        } else {
          next({name: 'index'});
        }
      },
    },
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/router/view/account/register'),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        if(store.state.login === SessionLoginState.NOT_LOGGED_IN) next();
        else next({name: 'index'});
      },
    },
  },
  {
    path: '/setup/legal',
    name: 'setup_legal',
    component: () => import('@/router/view/account/setup/legal'),
    meta: {
      auth_required: true,

    },
  },
  {
    path: '/setup/email/verify',
    name: 'setup_email_verify',
    component: () => import('@/router/view/account/setup/email_verify'),
    meta: {
      auth_required: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (store.state.login !== SessionLoginState.IN_SETUP) next({name: 'index'});
        else next();
      }
    },
  },
  {
    path: '/setup/email/verified',
    name: 'setup_email_verified',
    component: () => import('@/router/view/account/email_verified'),
    meta: {
      auth_required: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (store.state.login === SessionLoginState.LOGGED_IN) {
          next();
        } else next({name: 'setup_account'});
      }
    },
  },
  {
    path: '/recovery',
    name: 'recovery',
    component: () => import('@/router/view/account/recovery'),
    meta: {
      auth_required: false,
    },
  },
  {
    path: '/setup/email',
    name: 'setup_email',
    component: () => import('@/router/view/account/setup/email'),
    meta: {
      auth_required: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (store.state.login !== SessionLoginState.IN_SETUP) next({name: 'index'});
        else next();
      }
    },
  },
  {
    path: '/setup/username',
    name: 'setup_username',
    component: () => import('@/router/view/account/setup/username'),
    meta: {
      auth_required: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (store.state.login !== SessionLoginState.IN_SETUP) next({name: 'index'});
        else next();
      }
    },
  },
  {
    path: '/setup/password',
    name: 'setup_password',
    component: () => import('@/router/view/account/setup/password'),
    meta: {
      auth_required: true,
      beforeResolve(routeTo, routeFrom, next) {
        if (store.state.login !== SessionLoginState.IN_SETUP) next({name: 'index'});
        else next();
      }
    },
  },
  {
    path: '/setup',
    name: 'setup_account',
    meta: {
      auth_required: true,
      beforeResolve(routeTo, routeFrom, next) {
        fetch(process.env.VUE_APP_ROOT_API + 'v1/@me/setup-status', {credentials: 'include'})
        .then(response => {
          if(response.ok){
            return response.json();
          } else{
            if(response.status === 400 && response.json().data.error === 'invalid-state') { // Out of setup
              next({name: 'index'});
            }
            console.log(`[CRITICAL] Failed to connect core API: (${response.status})${response.statusText}`);
          }
        })
        .then(data => {
          store.commit('login', data.session.state);
          /* Sequential process, e-mail -> username -> password */
          if(!data.email.verified) {
            if(data.email.pending) {
              next({name: 'setup_email_verify'});
            } else {
              next({name: 'setup_email'});
            }
          } else if(!data.username.set) {
            next({name: 'setup_username'});
          } else if(!data.password.set) {
            next({name: 'setup_password'});
          } else {
            // Bad setup step
            next({name: 'index'});
          }
        }).catch(error => {
          console.log(`[ERROR] "${error}"`);
          this.$swal( {
            type: 'error',
            icon: 'error',
            title: this.$t('error.server.generic.title'),
            text: this.$t('error.server.generic.message')
          }).then(function() {
            location.reload();
          });
        });
      }
    },
  },
  {
    path: '/logout',
    name: 'logout',
    meta: {
      auth_required: true,
      beforeResolve(routeTo, routeFrom, next) {
        store.state.login = SessionLoginState.NOT_LOGGED_IN;
        store.state.cftools_id = null;

        fetch(process.env.VUE_APP_ROOT_API + 'v1/@me/acsrf-token', {credentials: 'include'})
        .then(response => {
          if(response.ok){
            return response.json();
          }
        })
        .then(data => {
          /* Actual request */
          let payload = {
            acsrf_token: data.acsrf_token
          };
          fetch(process.env.VUE_APP_ROOT_API + 'v1/@me/logout', {
            method: 'POST',
            body: JSON.stringify(payload),
            credentials: 'include'
          })
          .then(response => {
            if(response.ok){
              return response.json();
            }
          })
          .then(data => {
            console.log('Logging out...');
            store.commit('login', SessionLoginState.NOT_LOGGED_IN);
            store.commit('account', AccountStatus.NOT_VERIFIED);
            store.commit('user', null);
            location.reload();
          })
          .catch(error => {
            console.log(error);
          });
          /* --- */
        })
        .catch(error => {
          console.log(error);
        });

        next({ name: 'index' });
      },
    },
  },
]
