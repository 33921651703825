import Vue from 'vue'
import Vuex from 'vuex'

import modules from '@/state/modules'
import {AccountStatus, SessionLoginState} from '@/state/helpers'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules,
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  strict: process.env.NODE_ENV !== 'production',
  state: {
    login: SessionLoginState.NOT_LOGGED_IN,
    account: AccountStatus.NOT_VERIFIED,
    cftools_id: null
  },
  mutations: {
    login(state, val) {
      state.login = val;
    },
    account(state, val) {
      state.account = val;
    },
    user(state, cftools_id) {
      state.cftools_id = cftools_id;
    }
  },
  getters: {
    getUser: (state) => () => {
      return state.cftools_id;
    }
  }
})

export default store

