import Vue          from 'vue';
import App          from '@/App.vue';
import VueRouter    from 'vue-router';

import i18n         from '@/i18n';
import vco          from "v-click-outside";
import Vuelidate    from 'vuelidate';
import BootstrapVue from 'bootstrap-vue';
import io             from 'socket.io-client';
import VueSocketIOExt from 'vue-socket.io-extended';
import VueSweetalert2 from 'vue-sweetalert2';

import store        from '@/state/store';
import router       from '@/router/index';

import SessionLoginState from '@/state/helpers'

import "@/design/index.scss";

const socket = io(process.env.VUE_APP_WS_HOST, {
  path: '/connect',
  transports: ['websocket']
});

Vue.config.productionTip = false;

Vue.use(VueRouter);
Vue.use(BootstrapVue);
Vue.use(Vuelidate);
Vue.use(vco);
Vue.use(VueSocketIOExt, socket);
Vue.use(VueSweetalert2);

let ws_times = [];
let ws_time;

fetch(process.env.VUE_APP_ROOT_API + 'v1/@me/status', {credentials: 'include'})
.then(response => {
  if(response.ok){
    return response.json();
  } else{
    console.log(`[CRITICAL] Failed to connect core API: (${response.status})${response.statusText}`);
  }
})
.then(data => {
  store.commit('login', data.state.login);
  store.commit('account', data.state.account);
  store.commit('user', data.user.cftools_id);

  /* Initialize main Vue instance */
  new Vue({
    router,
    store,
    i18n,
    fallbackLocale: 'en',
    render: h => h(App),
    sockets: {
      connect() {
        setInterval(function() {
          ws_time = (new Date).getTime();
          socket.emit('keepalive');
        }, 5000);
      },
      ack(data) {
        console.log(`[WS] Connected to ${data.id}(${data.v})@${data.s}`);
      },
      keepalive(data) {
        var latency = (new Date).getTime() - ws_time;
        ws_times.push(latency);
        ws_times = ws_times.slice(-30);
        var sum = 0;
        for (var i = 0; i < ws_times.length; i++)
          sum += ws_times[i];

        var avg_latency = Math.round(10 * sum / ws_times.length) / 10;
        if(latency > 250.0)
          console.log(`[WS] (Warning) latency: ${latency}ms, avg-latency: ${avg_latency}ms`);
        socket.latency = avg_latency;
      }
    },
    created: function() {

    },
    methods: {

    }
  }).$mount('#app')
})
.catch(error => {
  console.log(`[ERROR] "${error}"`);
  //alert('Sorry, we have encountered an issue serving this request. Please refresh the page!');
  Vue.swal({
    icon: 'error',
    text: 'Sorry, we have encountered an issue serving this request'
  }).then(function(){
    location.reload();
  });
});
